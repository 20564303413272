@use '@shared/util-styling/mixins';

.results-list {
  display: grid;
  gap: var(--spacing-24);
  grid-template-columns: minmax(0, 1fr);

  @include mixins.breakpoint('medium') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mixins.breakpoint('large') {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.results-list-header {
  margin-bottom: var(--spacing-24);
  scroll-margin-top: var(--spacing-24);
}

.person-card-link {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.person-card {
  $_image-size: 112px;

  display: flex;
  gap: var(--spacing-16);
  padding: var(--spacing-16);

  &__photo-container {
    align-items: center;
    background-color: var(--mdrt-color-backgrounds-bg-surface-03);
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: $_image-size;
    justify-content: center;
    position: relative;
    width: $_image-size;
  }

  &__photo-placeholder-icon {
    color: var(--mdrt-color-text-on-light-placeholder);
    height: 32px;
  }

  &__photo {
    border-radius: inherit;
    height: $_image-size;
    object-fit: cover;
    width: $_image-size;
  }

  &__badge {
    bottom: 0;
    height: 40px;
    position: absolute;
    right: 0;
    width: 40px;
  }

  &__level-label {
    &--sr-only {
      @include mixins.visually-hidden;
    }
  }

  &__details {
    overflow: hidden;
  }

  &__name {
    @include mixins.ellipsis;

    display: block;
    font: var(--mdrt-headline-4);
  }

  &__designations {
    font: var(--mdrt-title-3);
    margin-top: var(--spacing-4);
  }

  &__company-name {
    font: var(--mdrt-title-4);
    margin-top: var(--spacing-8);
  }
}
