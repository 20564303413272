@use '@shared/util-styling/mixins';

.profile-photo-container {
  &__button {
    @include mixins.button-reset;

    align-items: center;
    background-color: var(--mdrt-color-backgrounds-bg-surface-03);
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }
}
